import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import * as firebase from 'firebase';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar
  ) {
    platform.ready().then(() => {
    statusBar.styleDefault();
    splashScreen.hide();
    let config = {
      apiKey: "AIzaSyCNYhLyvgP0EzWKQSrX_0tgqjiuxwm2YV8",
    authDomain: "testrealtime-8fd41.firebaseapp.com",
    databaseURL: "https://testrealtime-8fd41.firebaseio.com",
    projectId: "testrealtime-8fd41",
    storageBucket: "testrealtime-8fd41.appspot.com",
    messagingSenderId: "165096903465",
    appId: "1:165096903465:web:c3ca0221b1ce5a62435d38",
    measurementId: "G-95SLRQBZS9"
    };
     // Initialize Firebase
  firebase.initializeApp(config);
  firebase.analytics();
    });
  }

 
}
